<template>
  <div
    id="NftPool"
    class="nft_pool_stake"
  >
    <div class="top">
      <div class="left">
        <div class="title">
          {{ $t("stake.nft.title") }}
          <div class="subtitle">
            {{ $t("stake.nft.titleDesc") }}
          </div>
        </div>
        <div class="description">
          <div class="description-title" v-html="$t('stake.details.01')"></div>
          <div class="description-title-2">{{ $t("stake.details.02.title") }}
            <div class="list" v-html="$t('stake.details.02.description')"></div>
          </div>
          <div class="description-title-2">
            {{ $t("stake.details.03.title") }}
            <div class="list" v-html="$t('stake.details.03.description')"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <img
          v-if="artpoolSimpleInfo.bannerImagePcEnUrl && activeLanguage === 'en'"
          :src="artpoolSimpleInfo.bannerImagePcEnUrl"
        />
        <img
          v-else-if="artpoolSimpleInfo.bannerImagePcUrl && activeLanguage === 'ko'"
          :src="artpoolSimpleInfo.bannerImagePcUrl"
        />
        <img
          v-else
          :src="`/img/threespace/artpool/artpool_round${artpoolSimpleInfo.roundNo}_banner.png`"
        />
      </div>
    </div>

    <div class="staking-section">
      <div class="staking-pool">
        <div class="left">
          <div class="title-wrap">
            <div class="title">Stake NFTs on Ethereum</div>
            <div class="staking-type">ERC-721</div>
            <font-awesome-icon icon="exclamation-circle" :id="'staking-info'" />
            <b-tooltip :target="'staking-info'" triggers="hover">
              <p class="title">{{ $t("stake.nft.comment.unstake") }}</p>
              <p class="content">{{ $t("stake.nft.comment.01") }}</p>
              <p class="title">{{ $t("stake.nft.comment.claim") }}</p>
              <p class="content">{{ $t("stake.nft.comment.02") }}</p>
            </b-tooltip>
          </div>
          <div class="info-wrap">
            <div class="info-top">
              <div class="info-item">
                <div class="type">TOTAL</div>
                <div class="value">
                  <template v-if="nftStakingInfo !== undefined && nftStakingInfo.ercUserData !== undefined">
                    {{ nftStakingInfo.ercUserData.userReward | decimal }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  PACE
                </div>
              </div>
              <div class="info-item">
                <div class="type">NFTs</div>
                <div class="value">
                  <template v-if="nftStakingInfo !== undefined && nftStakingInfo.ercUserData !== undefined">
                    {{ nftStakingInfo.ercUserData.userAmountStaked || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </div>
              </div>
              <div class="btn-wrap">
                <b-button @click="onClickNftUnstake(artpoolInfo.ercNftPool, 'ERC-721')">
                  {{ $t("stake.nftUnstake") }}
                </b-button>
                <b-button @click="onClickClaim(artpoolInfo.ercNftPool, 'ERC-721')">{{ $t("claim.claim") }} PACE</b-button>
                <b-button
                  class="black"
                  @click="onClickNftStake(artpoolInfo.ercNftPool, 'ERC-721')"
                  :style="{
                  background: `linear-gradient(145deg,${artpoolInfo.primaryColor},${artpoolInfo.secondaryColor},${artpoolInfo.primaryColor},${artpoolInfo.secondaryColor},${artpoolInfo.primaryColor})`
                }"
                >
                  {{ $t("nftpool.stakeNft") }}
                </b-button>
              </div>
            </div>
            <div class="info-bottom">
              <div class="info-item">
                <div class="type">ALLOCATION</div>
                <div class="value">
                  <template v-if="artpoolInfo.ercNftPool !== undefined">
                    {{ getTotalAllocation(artpoolInfo.ercNftPool.rewardPerSecond, artpoolInfo.ercNftPool.startDate, artpoolInfo.ercNftPool.endDate) || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </div>
              </div>
              <div class="info-item">
                <div class="type">
                  TOTAL STAKED
                </div>
                <div class="value">
                  <template v-if="artpoolInfo.ercNftPool !== undefined">
                    {{ artpoolInfo.ercNftPool.totalNFT || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  NFTs
                </div>
              </div>
              <div class="info-item">
                <div class="type">
                  STAKED
                </div>
                <div class="value">
                  <template v-if="nftStakingInfo !== undefined && nftStakingInfo.ercUserData !== undefined">
                    {{ nftStakingInfo.ercUserData.userAmountStaked || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  NFTs
                </div>
              </div>
              <div class="info-item">
                <div class="type">
                  DAILY REWARD
                </div>
                <div class="value">
                  <template v-if="artpoolInfo.ercNftPool !== undefined">
                    {{ getDailyReward(artpoolInfo.ercNftPool.rewardPerSecond, artpoolInfo.ercNftPool.totalNFT) || 0 }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  PACE / NFT
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="product_slide">
            <b-carousel v-model="ercSlide" :interval="0" controls>
              <template v-if="stakedErcCollections.length > 0">
                <template v-for="(item, i) in stakedErcCollections">
                  <b-carousel-slide :key="'erc-item' + i">
                    <template #img>
                      <b-img :src="getCloudFrontImageUrl(item.imageUrl)"></b-img>
                    </template>
                    <p>
                      <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                        {{ item.title || item.titleEn }}
                      </template>
                      <template v-else>
                        {{ item.titleEn || item.title }}
                      </template>
                      <span>
                        <template v-if="item.tokenId > 0">
                          #{{ item.tokenId }}
                        </template>
                      </span>
                    </p>
                  </b-carousel-slide>
                </template>
              </template>
              <template v-else>
                <b-carousel-slide class="empty_slide">
                  <template #img>
                    <img :src="artpoolInfo.thumbnailImageUrl" />
                  </template>
                  <p>{{ $t("nftpool.noStakeNft") }}</p>
                </b-carousel-slide>
              </template>
            </b-carousel>
          </div>
        </div>
      </div>

      <div class="staking-pool">
        <div class="left">
          <div class="title-wrap">
            <div class="title">Stake NFTs ON Kaia</div>
            <div class="staking-type">KIP-17</div>
            <font-awesome-icon icon="exclamation-circle" :id="'staking-info-kip'" />
            <b-tooltip :target="'staking-info-kip'" triggers="hover">
              <p class="title">{{ $t("stake.nft.comment.unstake") }}</p>
              <p class="content">{{ $t("stake.nft.comment.01") }}</p>
              <p class="title">{{ $t("stake.nft.comment.claim") }}</p>
              <p class="content">{{ $t("stake.nft.comment.02") }}</p>
            </b-tooltip>
          </div>
          <div class="info-wrap">
            <div class="info-top">
              <div class="info-item">
                <div class="type">TOTAL</div>
                <div class="value">
                  <template v-if="nftStakingInfo !== undefined && nftStakingInfo.kipUserData !== undefined">
                    {{ nftStakingInfo.kipUserData.userReward | decimal }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  PACE
                </div>
              </div>
              <div class="info-item">
                <div class="type">NFTs</div>
                <div class="value">
                  <template v-if="nftStakingInfo !== undefined && nftStakingInfo.kipUserData !== undefined">
                    {{ nftStakingInfo.kipUserData.userAmountStaked || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </div>
              </div>
              <div class="btn-wrap">
                <b-button @click="onClickNftUnstake(artpoolInfo.kipNftPool, 'KIP-17')">
                  {{ $t("stake.nftUnstake") }}
                </b-button>
                <b-button @click="onClickClaim(artpoolInfo.kipNftPool, 'KIP-17')">{{ $t("claim.claim") }} PACE</b-button>
                <b-button
                  class="black"
                  @click="onClickNftStake(artpoolInfo.kipNftPool, 'KIP-17')"
                  :style="{
                  background: `linear-gradient(145deg,${artpoolInfo.primaryColor},${artpoolInfo.secondaryColor},${artpoolInfo.primaryColor},${artpoolInfo.secondaryColor},${artpoolInfo.primaryColor})`
                }"
                >
                  {{ $t("nftpool.stakeNft") }}
                </b-button>
              </div>
            </div>
            <div class="info-bottom">
              <div class="info-item">
                <div class="type">ALLOCATION</div>
                <div class="value">
                  <template v-if="artpoolInfo.kipNftPool !== undefined">
                    {{ getTotalAllocation(artpoolInfo.kipNftPool.rewardPerSecond, artpoolInfo.kipNftPool.startDate, artpoolInfo.kipNftPool.endDate) || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                </div>
              </div>
              <div class="info-item">
                <div class="type">
                  TOTAL STAKED
                </div>
                <div class="value">
                  <template v-if="artpoolInfo.kipNftPool !== undefined">
                    {{ artpoolInfo.kipNftPool.totalNFT || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  NFTs
                </div>
              </div>
              <div class="info-item">
                <div class="type">
                  STAKED
                </div>
                <div class="value">
                  <template v-if="nftStakingInfo !== undefined && nftStakingInfo.kipUserData !== undefined">
                    {{ nftStakingInfo.kipUserData.userAmountStaked || "0" }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  NFTs
                </div>
              </div>
              <div class="info-item">
                <div class="type">
                  DAILY REWARD
                </div>
                <div class="value">
                  <template v-if="artpoolInfo.kipNftPool !== undefined">
                    {{ getDailyReward(artpoolInfo.kipNftPool.rewardPerSecond, artpoolInfo.kipNftPool.totalNFT) || 0 }}
                  </template>
                  <template v-else>
                    0
                  </template>
                  PACE / NFT
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="product_slide">
            <b-carousel v-model="kipSlide" :interval="0" controls>
              <template v-if="stakedKipCollections.length > 0">
                <template v-for="(item, i) in stakedKipCollections">
                  <b-carousel-slide :key="'erc-item' + i">
                    <template #img>
                      <b-img :src="getCloudFrontImageUrl(item.imageUrl)"></b-img>
                    </template>
                    <p>
                      <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                        {{ item.title || item.titleEn }}
                      </template>
                      <template v-else>
                        {{ item.titleEn || item.title }}
                      </template>
                      <span>
                        <template v-if="item.tokenId > 0">
                          #{{ item.tokenId }}
                        </template>
                      </span>
                    </p>
                  </b-carousel-slide>
                </template>
              </template>
              <template v-else>
                <b-carousel-slide class="empty_slide">
                  <template #img>
                    <img :src="artpoolInfo.thumbnailImageUrl" />
                  </template>
                  <p>{{ $t("nftpool.noStakeNft") }}</p>
                </b-carousel-slide>
              </template>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>

    <NftPoolApproveModal
      ref="nftPoolApproveModal"
      @onClickNftApproveBtn="setNftApproval"
    />
    <NftPoolStakeSelectModal
      ref="nftPoolStakeSelectModal"
      :nft-type-info="nftTypeInfo"
      @onClickNftStakeBtn="setNftStake"
    />
    <NftPoolUnstakeSelectModal
      ref="nftPoolUnstakeSelectModal"
      :nft-type-info="nftTypeInfo"
      @onClickNftUnstakeBtn="setNftUnstake"
    />
    <ArtPoolUserGuideModal
      ref="artPoolUserGuideModal"
      :activeLanguage="activeLanguage"
      :artpoolInfo="artpoolInfo"
      @onClickPopupNoneSeenWeekBtn="onClickPopupNoneSeenWeekBtn"
    />
    <ArtPoolUserGuideModal2
      ref="artPoolUserGuideModal2"
      :activeLanguage="activeLanguage"
      :artpoolInfo="artpoolInfo"
      @onClickPopupNoneSeenWeekBtn="onClickPopupNoneSeenWeekBtn"
    />
    <NftPoolTopRankImageModal
      ref="nftPoolTopRankImageModal"
      :artpoolInfo="artpoolInfo"
      @onClickTopRankNoneSeenWeekBtn="onClickTopRankNoneSeenWeekBtn"
      @onClickTopRankCloseBtn="onClickTopRankCloseBtn"
    />
    <NftPoolTopRankImageModal2
      ref="nftPoolTopRankImageModal2"
      :artpoolInfo="artpoolInfo"
      @onClickTopRankNoneSeenWeekBtn="onClickTopRankNoneSeenWeekBtn"
      @onClickTopRankCloseBtn="onClickTopRankCloseBtn"
    />

    <NftPoolTopRankImageModal7
      ref="nftPoolTopRankImageModal7"
      :artpoolInfo="artpoolInfo"
      @onClickTopRankNoneSeenWeekBtn="onClickTopRankNoneSeenWeekBtn"
      @onClickTopRankCloseBtn="onClickTopRankCloseBtn"
    />

    <NftPoolTopRankImageModal8
      ref="nftPoolTopRankImageModal8"
      :artpoolInfo="artpoolInfo"
      @onClickTopRankNoneSeenWeekBtn="onClickTopRankNoneSeenWeekBtn"
      @onClickTopRankCloseBtn="onClickTopRankCloseBtn"
    />

    <NftPoolTopRankImageModal10
      ref="nftPoolTopRankImageModal10"
      :artpoolInfo="artpoolInfo"
      @onClickTopRankNoneSeenWeekBtn="onClickTopRankNoneSeenWeekBtn"
      @onClickTopRankCloseBtn="onClickTopRankCloseBtn"
    />

    <b-modal
      id="claimModal"
      ref="claimModal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      body-class="text-center text-black my-4"
    >
      <div class="claim-modal-title">{{ $t("claim.claimRequest") }}</div>
      <div class="claim-modal-content">
        <img src="/img/threespace/company/pace_logo.png" width="50"/>
        <div class="claim-modal-content-right">
          <span class="value">{{ nftStakingInfo.kipUserData && nftStakingInfo.kipUserData.userReward | decimal }}</span>
          <span class="text"> PACE </span>
        </div>
      </div>
      <div class="claim-modal-address">
        ETHEREUM {{ $t("claim.walletAddr") }}
        <CInput
          class="claim-modal-address-input"
          placeholder="PACE를 받을 이더리움 주소를 입력해주세요"
          v-model="claimEthereumAddress"
        />
      </div>
      <div class="claim-modal-notification important">
        <div class="claim-modal-notification-title">{{ $t("claim.guide.title") }}</div>
        <p>• 이더리움 체인상에서 PACE가 전달될 예정입니다. </p>
        <p>• {{ $t("claim.guide.content3") }}</p>
      </div>
      <div class="w-100 text-center mt-5">
        <div class="text-success mb-3">
          <template v-if="requestClaimPace">
            <b-spinner small type="grow" class="mr-2"></b-spinner>
            {{ $t("claim.processing") }}
          </template>
        </div>
        <b-button
          squared
          variant="secondary"
          class="px-4 py-2 mr-2"
          @click="closeClaimModal()"
        >
          {{ $t("button.close") }}
        </b-button>
        <b-button
          squared
          variant="primary"
          class="text-white px-4 py-2 ml-2"
          @click="claimRequest()"
          :disabled="requestClaimPace"
        >
          {{ $t("button.confirm") }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="klipModal"
      ref="klipModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.klipModal.hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <img
            src="/img/threespace/company/kakao_klip_logo_color.png"
            width="50"
            class="float-left"
          />
          <h5 class="ml-2 mb-0">{{ $t("wallet.modal.klipQRTitle") }}</h5>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img :src="klip.qrcode" width="160" height="160" class="border-1" />
        </div>
        <div class="w-100 text-center">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
        <div class="w-100 text-center mt-3">
          <p>
            {{ $t("wallet.modal.klipQRdescription") }}
          </p>
          <p class="text-gray">
            {{ $t("wallet.modal.klipQRdescription2") }}
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-logo.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-search.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-scan.svg" />
          </div>
        </div>
        <div
          class="row d-flex justify-content-center align-items-center text-center mt-2 mb-5"
        >
          <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
          <div class="col-3">{{ $t("wallet.klip.search") }}</div>
          <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapGetters, mapState} from "vuex";
import { prepare, request, getResult } from "klip-sdk";
import Caver from "caver-js";
import Web3 from "web3";
import QRCode from "qrcode";

import NftPoolApproveModal from "@/views/threespace/components/artpool/NftPoolApproveModal.vue";
import NftPoolStakeSelectModal from "@/views/threespace/components/artpool/NftPoolStakeSelectModal.vue";
import NftPoolUnstakeSelectModal from "@/views/threespace/components/artpool/NftPoolUnstakeSelectModal.vue";
import ArtPoolUserGuideModal from "@/views/threespace/components/artpool/ArtPoolUserGuideModal.vue";
import ArtPoolUserGuideModal2 from "@/views/threespace/components/artpool/ArtPoolUserGuideModal2.vue";
import NftPoolTopRankImageModal from '../components/artpool/NftPoolTopRankImageModal.vue';
import NftPoolTopRankImageModal2 from '../components/artpool/NftPoolTopRankImageModal2.vue';
import NftPoolTopRankImageModal7 from '../components/artpool/NftPoolTopRankImageModal7.vue';
import NftPoolTopRankImageModal8 from '../components/artpool/NftPoolTopRankImageModal8.vue';
import NftPoolTopRankImageModal10 from '../components/artpool/NftPoolTopRankImageModal10.vue';
import NeopinConnect from "nptconnect-client";
import WalletConnectUtil from "@/mixins/WalletConnectUtil";

const artpoolHelper = createNamespacedHelpers("artpool");
const authHelper = createNamespacedHelpers("auth");
const transactionHelper = createNamespacedHelpers("transaction");

export default {
  name: "NftPoolStakeV2",
  props: {
    idxArtpool: {
      type: Number,
      default: 0
    }
  },
  components: {
      NftPoolApproveModal
    , NftPoolStakeSelectModal
    , NftPoolUnstakeSelectModal
    , ArtPoolUserGuideModal
    , ArtPoolUserGuideModal2
    , NftPoolTopRankImageModal
    , NftPoolTopRankImageModal2
    , NftPoolTopRankImageModal7
    , NftPoolTopRankImageModal8
    , NftPoolTopRankImageModal10
  },
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  mixins: [WalletConnectUtil],
  data: function () {
    return {
      isBusy: false,
      perPage: 6,
      isLast: false,
      totalRows: 0,
      currentPage: 1,
      sort: [
        // { id: "endDate", desc: "desc" },
        // { id: "idx", desc: "desc" }
      ],
      externalParams: {
        keywordPoolType: "NFT",
      },
      nftTypeInfo: {
        idxArtpool: null,
        rfc: null
      },
      currentArtPool: 0,
      poolInfo: {},
      nftStakingInfo: {},
      stakedErcCollections: [],
      stakedKipCollections: [],
      poolContractAddr: null,
      selectedNfts: [],
      nftContractAddr: null,
      stakingCollections: [],
      prepareStakingCollections: [],
      unstakingCollections: [],
      date: this.$moment(60 * 3 * 1000),
      form: this.getInitPage(),
      klip: this.getInitKakaoKlip(),
      isOpenApprove: false,
      isOpenStake: false,
      isOpenUnstake: false,
      isOpenClaim: false,
      hasKlaytnAddr: false,
      hasEthereumAddr: false,
      ercSlide: 0,
      kipSlide: 0,
      cfOptions: {
        w: 300,
        h: 340,
        f: "webp",
        q: 90
      },
      requestCnt: 0,
      //TODO Tx
      isTimeout: false,
      txDate: this.$moment(60 * 10 * 1000), // 10분
      claimEthereumAddress: "",
      requestClaimPace: false
    }
  },
  computed: {
    ...artpoolHelper.mapState([
        "artpoolItems"
      , "artpoolItemsContent"
      , "artpoolSimpleInfo"
      , "artpoolInfo"
      , "artpoolVersionOptions"
    ]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      }
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState(["wallet"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    getKlaytnWalletProvider() {
      const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
      const walletProvider = this.me.klaytnProvider || walletInfo.walletProvider ;

      return walletProvider;
    },
    getEthereumWalletProvider() {
      const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
      const walletProvider = this.me.ethereumProvider || walletInfo.walletProvider ;

      return walletProvider;
    },
    time: function() {
      return this.date.format("mm : ss");
    },
    txTime: function() {
      return this.txDate.format("mm : ss");
    },
    getWalletProvider() {
      const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
      const walletProvider = this.me.klaytnProvider || walletInfo.walletProvider ;

      return walletProvider;
    }
  },
  mounted() {
    this.requestArtpools();
    this.$nextTick(() => {
    });
  },
  methods: {
    ...artpoolHelper.mapActions([
        "getArtpools"
      , "getArtpool"
      , "getMyNftStakingInfo"
      , "getMyStakedCollections"
      , "getKlaytnNftApprovalStatus"
      , "getEthereumNftApprovalStatus"
      , "prepareStakeCollections"
      , "completeStakeCollections"
      , "completeUnstakeCollections"
      , "getArtpoolCollectionsTopRank"
      , "addNftStakeApproveRequestedTx"
      , "addNftStakeRequestedTx"
      , "addNftUnstakeRequestedTx"
      , "claimRewardByArtpool"
    ]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...mapActions("wallet", ["getKlaytnTokenBalance", "getPaceBalance", "getNonce", "getTransactionResult"]),
    ...transactionHelper.mapActions(["getTxResult"]),
    requestArtpools() {
      this.$store.commit("setShowLoading", {show: true, msg: ""});
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(data => {
        if (this.currentPage === 1 && data.empty !== true) {
          this.requestArtpool();
        } else {
          this.isBusy = false;
          this.$log.info("Artpool information does not exist.");
        }
      })
      .catch(error => {
        this.isBusy = false;
        this.$log.error(error);
        this.$store.commit("setShowLoading", { show: false, msg: "" });
      });
    },
    requestArtpool() {
      this.getArtpool({ idxArtpool: this.idxArtpool })
        .then(data => {
          this.isBusy = false;
          this.currentArtPool = data.idx;
          // 로그인 되어 있는 경우 예치된 NFT 정보 조회
          this.verifyTokenCallback()
            .then(() => {
              if (this.me.idx !== undefined && this.me.idx !== null && this.me.idx !== "") {
                this.getMyNftStakingInfo({ idxArtpool: this.idxArtpool })
                  .then(data => {
                    this.nftStakingInfo = data;
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                  });
                this.getMyStakedCollections({ keywordIdxArtpool: this.idxArtpool, keywordRfc: "ERC-721" })
                  .then(data => {
                    this.stakedErcCollections = data;
                  });
                this.getMyStakedCollections({ keywordIdxArtpool: this.idxArtpool, keywordRfc: "KIP-17" })
                  .then(data => {
                    this.stakedKipCollections = data;
                  });
              }
            })
            .catch(error => {
              this.$log.error(error);
              this.$store.commit("setShowLoading", { show: false, msg: "" });
            })
          if (this.requestCnt === 0) {
            if (data.idx === 1) {
              // result popup
              if (this.$cookies.get("popupNotSeenForWeekArtPoolResult" + data.idx) === null) {
                this.$refs["nftPoolTopRankImageModal"].show();
              } else {
                // user guide popup
                // if (this.$cookies.get("popupNotSeenForWeekArtPoolUserGuide" + data.idx) === null) {
                //   this.$refs["artPoolUserGuideModal"].show();
                // }
              }
            } else if (data.idx === 2) {
              // result popup
              if (this.$cookies.get("popupNotSeenForWeekArtPoolResult" + data.idx) === null) {
                this.$refs["nftPoolTopRankImageModal2"].show();
              } else {
                // user guide popup
                // if (this.$cookies.get("popupNotSeenForWeekArtPoolUserGuide" + data.idx) === null) {
                //   this.$refs["artPoolUserGuideModal2"].show();
                // }
              }
            } else if (data.roundNo === 7) {
              // result popup
              if (this.$cookies.get("popupNotSeenForWeekArtPoolResult" + data.idx) === null) {
                this.$refs["nftPoolTopRankImageModal7"].show();
              } else {
                // user guide popup
                // if (this.$cookies.get("popupNotSeenForWeekArtPoolUserGuide" + data.idx) === null) {
                //   this.$refs["artPoolUserGuideModal2"].show();
                // }
              }
            } else if (data.roundNo === 8) {
              // result popup
              if (this.$cookies.get("popupNotSeenForWeekArtPoolResult" + data.idx) === null) {
                // this.$refs["nftPoolTopRankImageModal8"].show();
              } else {
                // user guide popup
                // if (this.$cookies.get("popupNotSeenForWeekArtPoolUserGuide" + data.idx) === null) {
                //   this.$refs["artPoolUserGuideModal2"].show();
                // }
              }
            } else if (data.roundNo === 10) {
              if (this.$cookies.get("popupNotSeenForWeekArtPoolResult" + data.idx) === null) {
                this.$refs["nftPoolTopRankImageModal10"].show();
              }
            }
            this.requestCnt++;
          }
        })
        .catch(error => {
          this.isBusy = false;
          this.$log.error(error);
          this.$store.commit("setShowLoading", { show: false, msg: "" });
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    isIngArtPool(startDate, endDate) {
      return this.$moment().isBetween(startDate, endDate)
    },
    getCloudFrontImageUrl(imageUrl) {
      return `${imageUrl}?w=${this.cfOptions.w}&h=${this.cfOptions.h}&f=${this.cfOptions.f}&q=${this.cfOptions.q}`;
    },
    onClickArtPoolListItem(artpool) {
      if(artpool.roundNo < 3 ) {
        this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      } else if(artpool.roundNo >=3 ) {
        this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      }
      // let version = this.artpoolVersionOptions.find(el => el.roundNo === artpool.roundNo).version;
      // if (version === 1 ) {
      //   this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      // } else if (version === 2) {
      //   this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      // }
    },
    onClickTopRankCloseBtn(idxArtpool) {
      // open user guide popup
      // if (this.$cookies.get("popupNotSeenForWeekArtPoolUserGuide" + idxArtpool) === null) {
      //   if (idxArtpool === 1) {
      //     this.$refs["artPoolUserGuideModal"].show();
      //   } else if (idxArtpool === 2) {
      //     this.$refs["artPoolUserGuideModal2"].show();
      //   }
      // }
    },
    onClickNftpoolDetail() {
      this.$router.push("/art-pool/nft/vote/" + this.idxArtpool);
    },
    onClickExternalLink(externalLink) {
      if (externalLink !== undefined && externalLink !== null && externalLink !== "") {
        if (this.$moment().isBefore(this.artpoolInfo.poolStartDate)) {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: "Coming Soon!",
            show: true,
            fontSize: "md"
          })
        } else {
          window.open(externalLink, "_blank");
        }
      } else {
        this.$log.info("External link information does not exist.");
      }
    },
    onClickAvailableNfts(idxArtpool) {
      if (idxArtpool !== undefined && idxArtpool > 0) {
        this.$router.push("/art-pool/artwork/" + idxArtpool);
      }
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    getDailyReward(rewardPerSecond, totalNFT) {
      if (rewardPerSecond === undefined || totalNFT === undefined) {
        return 0;
      }
      let reward = 0;
      if (totalNFT === "0") {
        reward = (rewardPerSecond * 60 * 60 * 24 / 10 ** 18);
      } else {
        reward = (rewardPerSecond * 60 * 60 * 24 / 10 ** 18 / totalNFT);
      }
      return reward
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    getTotalAllocation(rewardPerSecond, startDate, endDate){

      if (rewardPerSecond === undefined || startDate === undefined || endDate === undefined) {
        return 0;
      }

      const start = this.$moment(startDate);
      const end = this.$moment(endDate);
      const durationSeconds = end.diff(start, 'seconds');

      return (rewardPerSecond / 10 ** 18 * durationSeconds)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    checkKlaytnAddr() {
      return new Promise((resolve, reject) => {
        if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then(() => {
              if (
                this.me === undefined ||
                this.me === null ||
                this.me.idx === undefined ||
                this.me.idx === null ||
                this.me.idx === ""
              ) {
                // 로그인
                this.hasKlaytnAddr = false;
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("stake.requiredLogin"),
                  show: true,
                  fontSize: "sm"
                })
              } else {
                if (this.me.klaytnAddr === undefined || this.me.klaytnAddr === null || this.me.klaytnAddr === "") {
                  this.hasKlaytnAddr = false;
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("wallet.validation.requiredKlaytn"),
                    show: true,
                    fontSize: "sm"
                  })
                } else {
                  this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
                  this.hasKlaytnAddr = true;
                }
              }
              resolve(this.hasKlaytnAddr);
            })
        } else {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("stake.requiredLogin"),
            show: true,
            fontSize: "sm"
          })
          reject(this.hasKlaytnAddr);
        }
      })
    },
    checkEthereumAddr() {
      return new Promise((resolve, reject) => {
        if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then(() => {
              if (
                this.me === undefined ||
                this.me === null ||
                this.me.idx === undefined ||
                this.me.idx === null ||
                this.me.idx === ""
              ) {
                // 로그인
                this.hasEthereumAddr = false;
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("stake.requiredLogin"),
                  show: true,
                  fontSize: "sm"
                })
              } else {
                if (this.me.ethereumAddr === undefined || this.me.ethereumAddr === null || this.me.ethereumAddr === "") {
                  this.hasEthereumAddr = false;
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("wallet.validation.requiredEthereum"),
                    show: true,
                    fontSize: "sm"
                  })
                } else {
                  this.getPaceBalance();
                  this.hasEthereumAddr = true;
                }
              }
              resolve(this.hasEthereumAddr);
            })
        } else {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("stake.requiredLogin"),
            show: true,
            fontSize: "sm"
          })
          reject(this.hasEthereumAddr);
        }
      })
    },
    // NFT Stake >>>
    onClickNftStake(poolInfo, _rfc) {
      let nowInSeconds = Date.now() / 1000;
      console.log(parseInt(poolInfo.startTimestamp) && nowInSeconds <= parseInt(poolInfo.endTimestamp))
      if(nowInSeconds >= parseInt(poolInfo.startTimestamp) && nowInSeconds <= parseInt(poolInfo.endTimestamp)){
        if (_rfc === 'KIP-17') {
          this.checkKlaytnAddr()
            .then((hasKlatynAddr) => {
              if(hasKlatynAddr){
                this.poolInfo = poolInfo;
                this.poolContractAddr = poolInfo.contractAddress;

                // 작품 선택
                this.selectedNfts = [];
                this.nftTypeInfo.idxArtpool = this.artpoolInfo.idx;
                this.nftTypeInfo.rfc = _rfc;
                this.$refs["nftPoolStakeSelectModal"].show();
              }
            });
        } else if (_rfc === 'ERC-721') {
          this.checkEthereumAddr()
            .then((hasEthereumAddr) => {
              if(hasEthereumAddr){
                this.poolInfo = poolInfo;
                this.poolContractAddr = poolInfo.contractAddress;

                // 작품 선택
                this.selectedNfts = [];
                this.nftTypeInfo.idxArtpool = this.artpoolInfo.idx;
                this.nftTypeInfo.rfc = _rfc;
                this.$refs["nftPoolStakeSelectModal"].show();
              }
            });
        }
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.notInProgressPool"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    setNftStake(_selectedNfts) {
      // approval 상태 확인
      if (_selectedNfts !== undefined && _selectedNfts.length > 0) {
        this.selectedNfts = _selectedNfts;
        this.nftContractAddr = _selectedNfts[0].nft.contractAddr;
        const rfc = _selectedNfts[0].nft.rfc;
        if (rfc === "KIP-17") {
          this.getKlaytnNftApprovalStatus({ nftAddress: this.nftContractAddr })
            .then((isApproved) => {
              if (isApproved) {
                this.isOpenStake = true;
                // 스테이킹 실행
                this.executeNftStake("KIP-17");
              } else {
                this.isOpenApprove = true;
                this.$refs["nftPoolApproveModal"].show();
              }
            });
        } else if (rfc === "ERC-721") {
          this.getEthereumNftApprovalStatus({ nftAddress: this.nftContractAddr })
            .then((isApproved) => {
              if (isApproved) {
                this.isOpenStake = true;
                // 스테이킹 실행
                this.executeNftStake("ERC-721");
              } else {
                this.isOpenApprove = true;
                this.$refs["nftPoolApproveModal"].show();
              }
            });
        } else {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("stake.notSupported"),
            show: true,
            fontSize: "sm"
          })
        }
      }
    },
    // Wallet Check >>>
    checkKaikas() {
      if (window.klaytn === undefined || !window.klaytn) {
        alert(this.$t("wallet.validation.notFoundKaikas"));
        return false;
      }

      const kaikas = window.klaytn;

      if (
          kaikas.selectedAddress !== undefined &&
          kaikas.selectedAddress !== null &&
          kaikas.selectedAddress !== ""
      ) {
        if (kaikas.selectedAddress.toLowerCase() !== this.me.klaytnAddr.toLowerCase()) {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }
      } else {
        alert(this.$t("wallet.validation.checkWalletAddr"));
        return false;
      }

      if (
          kaikas.networkVersion === undefined ||
          kaikas.networkVersion === null ||
          kaikas.networkVersion.toString() !== process.env.VUE_APP_KLAYTN_CHAIN_ID
      ) {
        alert(this.$t("wallet.validation.checkNetwork"));
        return false;
      }
      return true;
    },
    checkMetamask() {
      if (window.ethereum === undefined || !window.ethereum) {
        alert(this.$t("wallet.validation.checkMetamask"));
        return false;
      }

      const metamask = window.ethereum;

      if (
          metamask.selectedAddress !== undefined &&
          metamask.selectedAddress !== null &&
          metamask.selectedAddress !== ""
      ) {
        if (metamask.selectedAddress.toLowerCase() !== this.me.ethereumAddr.toLowerCase()) {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }
      } else {
        alert(this.$t("wallet.validation.checkWalletAddr"));
        return false;
      }

      if (
          metamask.networkVersion === undefined ||
          metamask.networkVersion === null ||
          metamask.networkVersion.toString() !== process.env.VUE_APP_ETHEREUM_CHAIN_ID
      ) {
        alert(this.$t("wallet.validation.checkNetwork"));
        return false;
      }
      return true;
    },
    // Approval >>>
    setNftApproval() {
      let walletProvider = "";
      if (this.nftTypeInfo.rfc === 'KIP-17') {
        walletProvider = this.getKlaytnWalletProvider;
      } else if (this.nftTypeInfo.rfc === 'ERC-721') {
        walletProvider = this.getEthereumWalletProvider;
      }

      const approvalForAllABI = this.getABIApprovalForAll();

      if (walletProvider === "Kaikas") {
        this.kaikasApprovalForAll(approvalForAllABI);
      } else if (walletProvider === "Klip") {
        this.klipApprovalForAll(approvalForAllABI);
      } else if (walletProvider === "MetaMask") {
        this.metamaskApprovalForAll(approvalForAllABI);
      } else if (walletProvider.includes("wc-")) {
        this.walletConnectApprovalForAll(approvalForAllABI);
      } else if (walletProvider === "Neopin") {
        this.neopinApprovalForAll(approvalForAllABI);
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    // Kaikas Approval >>>
    kaikasApprovalForAll(approvalForAllABI) {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
            approvalForAllABI,
            [this.poolContractAddr, true]
        )

        caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: this.nftContractAddr,
          gas: 300000,
          data
        })
        .on('error', this.closeApprove)
        .on('receipt', this.onCompleteKlaytnApproval);
      }
    },
    onCompleteKlaytnApproval(receipt){
      if (receipt.status) {
        this.executeNftStake("KIP-17");
      } else {
        alert(this.$t("stake.failApprove"));
      }
      this.closeApprove();
    },
    closeApprove(){
      this.isOpenApprove = false;
      this.$refs["nftPoolApproveModal"].hide();
    },
    klipApprovalForAll(approvalForAllABI) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.nftContractAddr;
      const value = "0";
      const abi = JSON.stringify(approvalForAllABI);
      const params = `["${this.poolContractAddr}", true]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data, "approvalForAll");
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert(this.$t("stake.failRequest"));
        });
    },
    neopinApprovalForAll(approvalForAllABI) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          approvalForAllABI,
          [this.poolContractAddr, true]
        );

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.nftContractAddr,
            gas: 200000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteKlaytnApproval(receipt);
              })
              .catch((error) => {
                this.closeApprove(error);
              })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.closeApprove(error);
            })
        })
        .catch((error) => {
          this.$log.error("Can't Get Nonce : ", error);
          this.closeApprove(error);
          alert(error);
        })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    // Metamask Approval >>>
    metamaskApprovalForAll(approvalForAllABI) {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const data = web3.eth.abi.encodeFunctionCall(
            approvalForAllABI,
            [this.poolContractAddr, true]
        )

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: this.nftContractAddr,
          data
        })
        .on("transactionHash", (hash) => {
          this.$log.info("sendTransaction hash:", hash); //TODO
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.nftArtpool.wallet.prepared", {type: this.$t("loading.nftArtpool.type.stake")})
          });
          // 지갑승인 요청 TX 저장 / 결과 조회
          const requestedInfo = {
            status: "requested",
            chain: "ETHEREUM",
            transactionHash: hash,
            toAddr: this.nftContractAddr,
            state: "NFT_STAKE_APPROVE"
          };
          this.addNftStakeApproveRequestedTx(requestedInfo)
            .then(data => {
              this.$log.log("metamaskApprovalForAll() > addNftStakeApproveRequestedTx() data:", data); //TODO
              if (data.status !== undefined && data.status !== null && data.status === "requested") {
                // 타이머 초기화
                this.txDate = this.$moment(60 * 10 * 1000); // 10분
                this.isTimeout = false;
                clearInterval(this.timer);
                clearInterval(this.totalTimer);

                // 지갑승인 결과 조회 전체 타이머
                this.setTxTotalTimer("approve");
                // 지갑승인 결과 조회
                this.setTxResultTimer(data.requestKey, "ETHEREUM", "approve");
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.closeApprove(data); //TODO
              }
            })
            .catch(error => {
              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.closeApprove(error);
            })
        })
        //TODO
        // .on("receipt", (result) => {
        //   this.$log.info("sendTransaction result:", result);  //TODO
        //   if (result.status) {
        //     this.onCompleteEthereumApproval(result);
        //     this.$store.commit("setShowLoading", { show: false, msg: "" });
        //   } else {
        //     this.$store.commit("setShowLoading", { show: false, msg: "" });
        //     this.closeApprove(result);
        //   }
        // })
        .on("error", (error) => {
          this.$log.info("sendTransaction error:", error); //TODO
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.closeApprove(error);
        });
      }
    },
    onCompleteEthereumApproval(receipt){
      if (receipt.status) {
        this.executeNftStake("ERC-721");
      } else {
        alert(this.$t("stake.failApprove"));
      }
      this.closeApprove();
    },
    // WalletConnect Approval >>>
    async walletConnectApprovalForAll(approvalForAllABI){
      const provider = await this.getWalletConnectProvider();

      if (provider) {
        const web3 = new Web3(provider);
        const data = web3.eth.abi.encodeFunctionCall(
          approvalForAllABI,
          [this.poolContractAddr, true]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: this.nftContractAddr,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.log("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.prepared", {type: this.$t("loading.nftArtpool.type.stake")})
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.onCompleteEthereumApproval(result);
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    this.closeApprove(result);
                    clearInterval(timer);
                  }
                }
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("처리가 지연되고 있습니다. 관리자에게 문의하세요."),
                  show: true,
                  fontSize: "sm"
                })
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("sendTransaction error:", error);
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.closeApprove(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    // NFT Stake >>>
    executeNftStake(_rfc) {
      let walletProvider = "";
      if (_rfc === "KIP-17") {
        walletProvider = this.getKlaytnWalletProvider;
      } else if (_rfc === "ERC-721") {
        walletProvider = this.getEthereumWalletProvider;
      }

      this.stakingCollections = [];
      this.prepareStakingCollections = [];

      let tokenIds = new Array();
      if (this.selectedNfts !== undefined && this.selectedNfts.length > 0) {
        this.selectedNfts.forEach(item => {
          if (item.tokenId !== undefined && item.tokenId != null && item.tokenId !== "") {
            tokenIds.push(item.tokenId);
            this.stakingCollections.push(item.idx);
          }
        });
        if (tokenIds.length === 0) {
          this.$log.log(this.$t("stake.stakeModal.noCollection"));
          return;
        }
      } else {
        this.$log.log(this.$t("stake.stakeModal.noCollection"));
        return;
      }

      // 스테이킹 대상 컬렉션 정보 저장
      const param = {
        idxArtpool: this.artpoolInfo.idx,
        collections: this.stakingCollections
      };

      this.prepareStakingCollections = [];
      this.prepareStakeCollections(param)
        .then(data => {
          this.prepareStakingCollections = data;
        })
        .catch(error => {
          this.$log.error(error);
          alert(this.$t("stake.staking.failPrepare"));
          return;
        });

      const nftStakeABI = this.getABINftBatchStake();
      const params = {
        round: 0,
        nftAddress: this.nftContractAddr,
        tokenIds: tokenIds
      };
      if (walletProvider === "Kaikas") {
        params.round = this.artpoolInfo.roundNoKip;
        this.kaikasNftStake(nftStakeABI, params);
      } else if (walletProvider === "MetaMask") {
        params.round = this.artpoolInfo.roundNoErc;
        this.metamaskNftStake(nftStakeABI, params);
      } else if (walletProvider === "Klip") {
        params.round = this.artpoolInfo.roundNoKip;
        this.klipNftStake(nftStakeABI, params);
      } else if (walletProvider === "Neopin") {
        params.round = this.artpoolInfo.roundNoKip;
        this.neopinNftStake(nftStakeABI, params);
      } else if(walletProvider.includes("wc-")) {
        params.round = this.artpoolInfo.roundNoErc;
        this.walletConnectNftStake(nftStakeABI, params);
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    kaikasNftStake(nftStakeABI, params) {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
            nftStakeABI,
            [params.round, params.nftAddress, params.tokenIds]
        );

        let gasLimit = 500000;
        if (params.tokenIds.length > 1) {
          gasLimit = gasLimit + (100000 * (params.tokenIds.length - 1));
        }

        caver.klay.sendTransaction({
          type: "SMART_CONTRACT_EXECUTION",
          from: this.me.klaytnAddr,
          to: this.poolContractAddr,
          gas: gasLimit,
          data
        })
        .on("error", this.onErrorNftStake)
        .on("receipt", this.onCompleteNftStake);
      }
    },
    klipNftStake(nftStakeABI, payload) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.poolContractAddr;
      const value = "0";
      const abi = JSON.stringify(nftStakeABI);
      const params = `["${payload.round}", "${payload.nftAddress}", ${JSON.stringify(payload.tokenIds)}]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data, "stake");
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert(this.$t("stake.failRequest"));
        });
    },
    neopinNftStake(nftStakeABI, params) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          nftStakeABI,
          [params.round, params.nftAddress, params.tokenIds]
        );

        let gasLimit = 500000;
        if (params.tokenIds.length > 1) {
          gasLimit = gasLimit + (100000 * (params.tokenIds.length - 1));
        }

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.poolContractAddr,
            gas: gasLimit,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteNftStake(receipt);
              })
                .catch((error) => {
                  this.onErrorNftStake(error)
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.onErrorNftStake(error)
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.onErrorNftStake(error)
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    metamaskNftStake(nftStakeABI, params) {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const data = web3.eth.abi.encodeFunctionCall(
            nftStakeABI,
            [params.round, params.nftAddress, params.tokenIds]
        );

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        })
        .on("transactionHash", (hash) => {
          this.$log.info("sendTransaction hash:", hash); //TODO
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.nftArtpool.wallet.progressing", { type: this.$t("loading.nftArtpool.type.stake") })
          });
          // 스테이크 요청 TX 저장 / 결과 조회
          const requestedInfo = {
            status: "requested",
            chain: "ETHEREUM",
            transactionHash: hash,
            state: "NFT_STAKE",
            toAddr: this.poolContractAddr,
            remarks: params,
            idxArtpool: this.artpoolInfo.idx,
            collections: this.prepareStakingCollections
          };
          this.$log.log("metamaskNftStake() requestedInfo:", requestedInfo); //TODO
          this.addNftStakeRequestedTx(requestedInfo)
            .then(data => {
              this.$log.log("metamaskNftStake() > addNftStakeRequestedTx() data:", data); //TODO
              if (data.status !== undefined && data.status !== null && data.status === "requested") {
                // 타이머 초기화
                this.txDate = this.$moment(60 * 10 * 1000); // 10분
                this.isTimeout = false;
                clearInterval(this.timer);
                clearInterval(this.totalTimer);

                // 스테이크 결과 조회 전체 타이머
                this.setTxTotalTimer("stake");
                // 스테이크 결과 조회
                this.setTxResultTimer(data.requestKey, "ETHEREUM", "stake");
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.onErrorNftStake(data); //TODO
              }
            })
            .catch(error => {
              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.onErrorNftStake(error);
            })
        })
        // TODO
        // .on("receipt", (result) => {
        //   this.$log.info("sendTransaction result:", result);  //TODO
        //   if (result.status) {
        //     this.onCompleteNftStake(result);
        //     this.$store.commit("setShowLoading", { show: false, msg: "" });
        //   } else {
        //     this.$store.commit("setShowLoading", { show: false, msg: "" });
        //     this.onErrorNftStake(result);
        //   }
        // })
        .on("error", (error) => {
          this.$log.info("sendTransaction error:", error); //TODO
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.onErrorNftStake(error);
        });
      }
    },
    setTxTotalTimer(functionType) {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.txDate = this.txDate.clone().subtract(1, "seconds");
          if (this.txTime === "00 : 00") {
            // 타이머 초기화
            this.isTimeout = true;
            clearInterval(this.timer);
            clearInterval(this.totalTimer);

            this.$store.commit("setShowLoading", { show: false, msg: "" });

            // 유형별 추가 처리 실행
            if (functionType === "approve") {
              this.closeApprove();
            } else if (functionType === "stake") {
              this.closeNftStake();
            } else if (functionType === "unstake") {
              this.closeNftUnstake();
            }
            // 메시지 팝업 > TODO 메시지 수정필요
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: "블록체인 트랜잭션 처리가 지연되고 있습니다. 잠시 후 처리결과를 확인해주세요.",
              show: true,
              fontSize: "sm"
            });
          }
        }, 1000);
      }
    },
    setTxResultTimer(_requestKey, chain, functionType) {
      // 처리결과 메시지 설정(디폴트)
      let message = "";
      if (functionType === "approve") {
        message = this.$t("stake.failApprove");
      } else if (functionType === "stake") {
          message = this.$t("stake.staking.failRequest");
      } else if (functionType === "unstake") {
        message = this.$t("stake.unstaking.failRequest");
      }

      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          this.getTxResult(_requestKey)
            .then(data => {
              this.$log.log("getTxResult() data=", data); //TODO
              if (data.status === "completed") {
                // 타이머 초기화
                this.isTimeout = true;
                clearInterval(this.timer);
                clearInterval(this.totalTimer);

                this.$store.commit("setShowLoading", { show: false, msg: "" });

                // 유형별 추가 처리 실행
                if (functionType === "approve") {
                  this.closeApprove();
                } else if (functionType === "stake") {
                  this.closeNftStake();
                } else if (functionType === "unstake") {
                  this.closeNftUnstake();
                }

                if (data.resultStatus === "success") {
                  // 유형별 추가 처리 실행
                  if (functionType === "approve") {
                    message = this.$t("stake.staking.success");
                    // 스테이크 요청
                    this.executeNftStake("ERC-721");
                  } else if (functionType === "stake") {
                    message = this.$t("stake.staking.success");
                    this.requestArtpool(this.artpoolInfo.idx);
                  } else if (functionType === "unstake") {
                    message = this.$t("stake.unstaking.success");
                    this.requestArtpool(this.artpoolInfo.idx);
                  }
                } else if (data.resultStatus === "fail") {
                  // 유형별 추가 처리 실행
                }

                // 메시지 팝업
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: message,
                  show: true,
                  fontSize: "sm"
                });
              }
            })
            .catch(error => {
              this.$log.error(error);
              // 타이머 초기화
              this.isTimeout = true;
              clearInterval(this.timer);
              clearInterval(this.totalTimer);

              this.$store.commit("setShowLoading", { show: false, msg: "" });

              // 유형별 추가 처리 실행
              if (functionType === "approve") {
                this.closeApprove();
              } else if (functionType === "stake") {
                this.closeNftStake();
              } else if (functionType === "unstake") {
                this.closeNftUnstake();
              }

              // 메시지 팝업
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: message,
                show: true,
                fontSize: "sm"
              });
            });
        }, 3000);
      }
    },
    async walletConnectNftStake(nftStakeABI, params) {
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);
        const data = web3.eth.abi.encodeFunctionCall(
          nftStakeABI,
          [params.round, params.nftAddress, params.tokenIds]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash) => {
            this.$log.info("WalletConnect sendTransaction:", hash);
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.progressing", { type: this.$t("loading.nftArtpool.type.stake") })
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.onCompleteNftStake(result);
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    this.onErrorNftStake(result);
                    clearInterval(timer);
                  }
                }
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("처리가 지연되고 있습니다. 관리자에게 문의하세요."),
                  show: true,
                  fontSize: "sm"
                })
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("WalletConnect sendTransaction:", error);
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.onErrorNftStake(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onErrorNftStake(error) {
      this.$log.info("onErrorNftStake:", error); //TODO
      alert(this.$t("stake.staking.failRequest"));
      this.closeNftStake();
    },
    onCompleteNftStake(receipt) {
      if (receipt.status) {
        this.closeNftStake();

        // 스테이킹 결과 저장
        const param = {
          idxArtpool: this.artpoolInfo.idx,
          collections: this.prepareStakingCollections
        };
        this.completeStakeCollections(param)
          .then(data => {
            this.requestArtpool(param.idxArtpool);
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.staking.success"),
              show: true,
              fontSize: "sm"
            })
          })
          .catch(error => {
            this.$log.error(error);
          });
      } else {
        alert(this.$t("stake.staking.failRequest"));
        this.closeNftStake();
      }
    },
    closeNftStake(){
      this.isOpenStake = false;
      this.$refs["nftPoolStakeSelectModal"].hide();
    },
    // NFT Unstake >>>
    onClickNftUnstake(poolInfo, _rfc) {
      let nowInSeconds = Date.now() / 1000;
      if (!poolInfo.fullLockup || nowInSeconds > parseInt(poolInfo.endTimestamp)) {
        if (_rfc === 'KIP-17') {
          this.checkKlaytnAddr()
            .then((hasKlatynAddr) => {
              if (hasKlatynAddr) {
                if (
                  this.nftStakingInfo === undefined ||
                  this.nftStakingInfo.kipUserData === undefined ||
                  this.nftStakingInfo.kipUserData.userAmountStaked === "0") {
                  // 예치된 수랑이 없다면
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("nftpool.noStakeNft"),
                    show: true,
                    fontSize: "sm"
                  })
                  return false
                }
                this.poolInfo = poolInfo;
                this.poolContractAddr = poolInfo.contractAddress;

                // 작품 선택
                this.selectedNfts = [];
                this.nftTypeInfo.idxArtpool = this.artpoolInfo.idx;
                this.nftTypeInfo.rfc = _rfc;
                this.$refs["nftPoolUnstakeSelectModal"].show();
              }
            });
        } else if (_rfc === 'ERC-721') {
          this.checkEthereumAddr()
            .then((hasEthereumAddr) => {
              if (hasEthereumAddr) {
                if (
                  this.nftStakingInfo === undefined ||
                  this.nftStakingInfo.ercUserData === undefined ||
                  this.nftStakingInfo.ercUserData.userAmountStaked === "0") {
                  // 예치된 수랑이 없다면
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("nftpool.noStakeNft"),
                    show: true,
                    fontSize: "sm"
                  })
                  return false
                }
                this.poolInfo = poolInfo;
                this.poolContractAddr = poolInfo.contractAddress;

                // 작품 선택
                this.selectedNfts = [];
                this.nftTypeInfo.idxArtpool = this.artpoolInfo.idx;
                this.nftTypeInfo.rfc = _rfc;
                this.$refs["nftPoolUnstakeSelectModal"].show();
              }
            });
        }
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.cantUnstake"),
          show: true,
          fontSize: "sm"
        })
      }
    },
    setNftUnstake(_selectedNfts) {
      if (_selectedNfts !== undefined && _selectedNfts.length > 0) {
        this.selectedNfts = _selectedNfts;
        this.nftContractAddr = _selectedNfts[0].nft.contractAddr;
        const rfc = _selectedNfts[0].nft.rfc;
        this.isOpenUnstake = true;
        // 언스테이킹 실행
        this.executeNftBatchUnstake(rfc); // 복수개 가능
      }
    },
    executeNftBatchUnstake(_rfc) {
      let walletProvider = "";
      if (_rfc === "KIP-17") {
        walletProvider = this.getKlaytnWalletProvider;
      } else if (_rfc === "ERC-721") {
        walletProvider = this.getEthereumWalletProvider;
      }

      this.unstakingCollections = [];

      let tokenIds = new Array();
      if (this.selectedNfts !== undefined && this.selectedNfts.length > 0) {
        this.selectedNfts.forEach(item => {
          if (item.tokenId !== undefined && item.tokenId != null && item.tokenId !== "") {
            tokenIds.push(item.tokenId);
            this.unstakingCollections.push(item);
          }
        });
        if (tokenIds.length === 0) {
          this.$log.info(this.$t("stake.stakeModal.noCollection"));
          return;
        }
      } else {
        this.$log.info(this.$t("stake.stakeModal.noCollection"));
        return;
      }

      const nftUnstakeABI = this.getABINftBatchUnstake();
      const params = {
        round: 0,
        nftAddress: this.nftContractAddr,
        tokenIds: tokenIds
      };
      if(walletProvider === "Kaikas") {
        params.round = this.artpoolInfo.roundNoKip;
        this.kaikasNftBatchUnstake(nftUnstakeABI, params);
      } else if (walletProvider === "MetaMask") {
        params.round = this.artpoolInfo.roundNoErc;
        this.metamaskNftBatchUnstake(nftUnstakeABI, params);
      } else if(walletProvider === "Klip") {
        params.round = this.artpoolInfo.roundNoKip;
        this.klipNftBatchUnstake(nftUnstakeABI, params);
      } else if(walletProvider.includes("wc-")) {
        params.round = this.artpoolInfo.roundNoErc;
        this.walletConnectBatchNftUnstake(nftUnstakeABI, params);
      } else if(walletProvider === "Neopin") {
        params.round = this.artpoolInfo.roundNoKip;
        this.neopinNftBatchUnstake(nftUnstakeABI, params);
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    kaikasNftBatchUnstake(nftUnstakeABI, params) {
      if (this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
            nftUnstakeABI,
            [params.round, params.nftAddress, params.tokenIds]
        )

        let gasLimit = 500000;
        if (params.tokenIds.length > 1) {
          gasLimit = gasLimit + (100000 * (params.tokenIds.length - 1));
        }

        caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: this.poolContractAddr,
          gas: gasLimit,
          data
        })
        .on('error', this.onErrorNftUnstake)
        .on('receipt', this.onCompleteNftUnstake)
      }
    },
    neopinNftBatchUnstake(nftUnstakeABI, params) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          nftUnstakeABI,
          [params.round, params.nftAddress, params.tokenIds]
        )

        let gasLimit = 500000;
        if (params.tokenIds.length > 1) {
          gasLimit = gasLimit + (100000 * (params.tokenIds.length - 1));
        }

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.poolContractAddr,
            gas: gasLimit,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteNftUnstake(receipt);
              })
                .catch((error) => {
                  this.onErrorNftUnstake(error)
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.onErrorNftUnstake(error)
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.onErrorNftUnstake(error)
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    metamaskNftBatchUnstake(nftUnstakeABI, params) {
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const data = web3.eth.abi.encodeFunctionCall(
            nftUnstakeABI,
            [params.round, params.nftAddress, params.tokenIds]
        )

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        })
        .on("transactionHash", (hash) => {
          this.$log.info("sendTransaction hash:", hash); //TODO
          this.$store.commit("setShowLoading", {
            show: true,
            msg: this.$t("loading.nftArtpool.wallet.progressing", { type: this.$t("loading.nftArtpool.type.unstake") })
          });
          // 언스테이크 요청 TX 저장 / 결과 조회
          const requestedInfo = {
            status: "requested",
            chain: "ETHEREUM",
            transactionHash: hash,
            state: "NFT_UNSTAKE",
            toAddr: this.poolContractAddr,
            remarks: params,
            idxArtpool: this.artpoolInfo.idx,
            collections: this.unstakingCollections
          };
          this.$log.log("metamaskNftBatchUnstake() requestedInfo:", requestedInfo); //TODO
          this.addNftUnstakeRequestedTx(requestedInfo)
            .then(data => {
              this.$log.log("metamaskNftBatchUnstake() > addNftUnstakeRequestedTx() data:", data); //TODO
              if (data.status !== undefined && data.status !== null && data.status === "requested") {
                // 타이머 초기화
                this.txDate = this.$moment(60 * 10 * 1000); // 10분
                this.isTimeout = false;
                clearInterval(this.timer);
                clearInterval(this.totalTimer);

                // 언스테이크 결과 조회 전체 타이머
                this.setTxTotalTimer("unstake");
                // 언스테이크 결과 조회
                this.setTxResultTimer(data.requestKey, "ETHEREUM", "unstake");
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.onErrorNftUnstake(data); //TODO
              }
            })
            .catch(error => {
              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.onErrorNftUnstake(error);
            })
        })
        //TODO
        // .on("receipt", (result) => {
        //   this.$log.info("sendTransaction result:", result);  //TODO
        //   if (result.status) {
        //     this.onCompleteNftUnstake(result);
        //     this.$store.commit("setShowLoading", { show: false, msg: "" });
        //   } else {
        //     this.$store.commit("setShowLoading", { show: false, msg: "" });
        //     this.onErrorNftUnstake(result);
        //   }
        // })
        .on("error", (error) => {
          this.$log.info("sendTransaction error:", error); //TODO
          this.$store.commit("setShowLoading", { show: false, msg: "" });
          this.onErrorNftUnstake(error);
        });
      }
    },
    klipNftBatchUnstake(nftUnstakeABI, payload) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.poolContractAddr;
      const value = "0";
      const abi = JSON.stringify(nftUnstakeABI);
      const params = `["${payload.round}", "${payload.nftAddress}", ${JSON.stringify(payload.tokenIds)}]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data, "unstake");
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert(this.$t("stake.failRequest"));
        });
    },
    async walletConnectBatchNftUnstake(nftUnstakeABI, params) {
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);
        const data = web3.eth.abi.encodeFunctionCall(
          nftUnstakeABI,
          [params.round, params.nftAddress, params.tokenIds]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.info("sendTransaction hash:", hash);
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.progressing", { type: this.$t("loading.nftArtpool.type.unstake") })
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.onCompleteNftUnstake(result);
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    clearInterval(timer);
                  }else {
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    this.onErrorNftUnstake(result);
                    clearInterval(timer);
                  }
                }
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("처리가 지연되고 있습니다. 관리자에게 문의하세요."),
                  show: true,
                  fontSize: "sm"
                })
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("sendTransaction error:", error);
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.onErrorNftUnstake(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onErrorNftUnstake(error) {
      this.$log.info("onErrorNftUnstake:", error); //TODO
      alert(this.$t("stake.unstaking.failRequest"));
      this.closeNftUnstake();
    },
    onCompleteNftUnstake(receipt) {
      if (receipt.status) {
        this.closeNftUnstake();

        // 언스테이킹 결과 저장
        const param = {
          idxArtpool: this.artpoolInfo.idx,
          collections: this.unstakingCollections
        };
        this.completeUnstakeCollections(param)
          .then(() => {
            this.requestArtpool(param.idxArtpool);
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("stake.unstaking.success"),
              show: true,
              fontSize: "sm"
            })
          })
          .catch(error => {
            this.$log.error(error);
          });
      } else {
        alert(this.$t("stake.unstaking.failRequest"));
        this.closeNftUnstake();
      }
    },
    closeNftUnstake(){
      this.isOpenUnstake = false;
      this.$refs["nftPoolUnstakeSelectModal"].hide();
    },
    onClickClaim(poolInfo, _rfc){
      let walletProvider = "";
      const claimABI = this.getABIClaim();

      if (_rfc === 'KIP-17') {
        this.checkKlaytnAddr()
          .then((hasKlatynAddr) => {
            if(hasKlatynAddr){
              if (
                this.nftStakingInfo === undefined ||
                this.nftStakingInfo.kipUserData === undefined ||
                this.nftStakingInfo.kipUserData.userReward === "0"
              ) {
                // 클레임 가능한 수량이 없다면
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("stake.claim.noAmount"),
                  show: true,
                  fontSize: "sm"
                })
                return false;
              }

              if(this.artpoolSimpleInfo.roundNo >= 6){
                if(Date.now() / 1000 > parseInt(poolInfo.endTimestamp)){
                  this.$refs.claimModal.show();
                } else {
                  this.$store.commit("setCommonAlertModalInfo", {
                    msgHtml: this.$t("stake.claim.notFinish"),
                    show: true,
                    fontSize: "sm"
                  })
                }
                return false;
              }

              this.poolInfo = poolInfo;
              this.poolContractAddr = poolInfo.contractAddress;

              //TODO 작품 선택
              this.nftTypeInfo.idxArtpool = this.artpoolInfo.idx;
              this.nftTypeInfo.rfc = _rfc;
              walletProvider = this.getKlaytnWalletProvider;

              if (walletProvider === "Kaikas") {
                this.kaikasClaim(claimABI, this.artpoolInfo.roundNoKip);
              } else if (walletProvider === "Klip") {
                this.klipClaim(claimABI, this.artpoolInfo.roundNoKip);
              } else if (walletProvider === "Neopin") {
                this.neopinClaim(claimABI, this.artpoolInfo.roundNoKip);
              } else {
                alert(this.$t("wallet.validation.checkProvider"));
              }
            }
          });
      } else if (_rfc === 'ERC-721') {
        this.checkEthereumAddr()
          .then((hasEthereumAddr) => {
            if(hasEthereumAddr){
              if (
                this.nftStakingInfo === undefined ||
                this.nftStakingInfo.ercUserData === undefined ||
                this.nftStakingInfo.ercUserData.userReward === "0"
              ) {
                // 클레임 가능한 수량이 없다면
                this.$store.commit("setCommonAlertModalInfo", {
                  msgHtml: this.$t("stake.claim.noAmount"),
                  show: true,
                  fontSize: "sm"
                })
                return false;
              }
              this.poolInfo = poolInfo;
              this.poolContractAddr = poolInfo.contractAddress;

              //TODO 작품 선택
              this.nftTypeInfo.idxArtpool = this.artpoolInfo.idx;
              this.nftTypeInfo.rfc = _rfc;
              walletProvider = this.getEthereumWalletProvider;

              if (walletProvider === "MetaMask") {
                this.metamaskClaim(claimABI, this.artpoolInfo.roundNoErc);
              } else if (walletProvider.includes("wc-")) {
                this.walletConnectClaim(claimABI, this.artpoolInfo.roundNoErc);
              } else {
                alert(this.$t("wallet.validation.checkProvider"));
              }
            }
          });
      }
    },
    kaikasClaim(claimABI, round) {
      if(this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
          claimABI,
          [round]
        );

        caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: this.poolContractAddr,
          gas: 300000,
          data
        }).on('error', this.onFailClaim)
          .on('receipt', this.onCompleteClaim)
      }
    },
    neopinClaim(claimABI, round) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          claimABI,
          [round]
        );

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.poolContractAddr,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteClaim(receipt);
              })
                .catch((error) => {
                  this.onFailClaim(error)
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.onFailClaim(error)
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.onFailClaim(error)
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onCompleteClaim(receipt){
      if(receipt.status){
        this.requestArtpool(this.artpoolInfo.idx);
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.claim.success"),
          show: true,
          fontSize: "sm"
        })
      }else {
        this.onFailClaim();
      }
    },
    onFailClaim() {
      alert(this.$t("stake.claim.fail"));
    },
    klipClaim(claimABI, round) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.poolContractAddr;
      const value = "0";
      const abi = JSON.stringify(claimABI);
      const params = `["${round}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data, "claim");
        })
        .catch(error => {
          this.$log.error(error);
          alert(this.$t("stake.failRequest"));
        });
    },
    metamaskClaim(claimABI, round){
      if (this.checkMetamask()) {
        const web3 = new Web3(window.ethereum);
        const data = web3.eth.abi.encodeFunctionCall(
          claimABI,
          [round]
        );

        web3.eth.sendTransaction({
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        })
          .on("transactionHash", (hash) => {
            this.$log.info("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.progressing", { type: this.$t("loading.nftArtpool.type.claim") })
            });
          })
          .on("receipt", (result) => {
            this.$log.info("sendTransaction result:", result);  //TODO
            if (result.status) {
              this.onCompleteClaim(result);
              this.$store.commit("setShowLoading", { show: false, msg: "" });
            } else {
              this.$store.commit("setShowLoading", { show: false, msg: "" });
              this.onFailClaim();
            }
          })
          .on("error", (error) => {
            this.$log.info("sendTransaction error:", error); //TODO
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.onFailClaim();
          });
      }
    },
    async walletConnectClaim(claimABI, round){
      const provider = await this.getWalletConnectProvider();

      if(provider){
        const web3 = new Web3(provider);
        const data = web3.eth.abi.encodeFunctionCall(
          claimABI,
          [round]
        );

        const tx = {
          from: this.me.ethereumAddr,
          to: this.poolContractAddr,
          data
        }

        const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_INFURA_URL))
        let maxPolling = 10;
        let pollingInterval = 15000;

        provider.request({
          method: 'eth_sendTransaction',
          params: [tx]
        })
          .then((hash)  => {
            this.$log.log("sendTransaction hash:", hash); //TODO
            this.$store.commit("setShowLoading", {
              show: true,
              msg: this.$t("loading.nftArtpool.wallet.prepared", {type: this.$t("loading.nftArtpool.type.claim")})
            });
            const timer = setInterval(() => infuraWeb3.eth.getTransactionReceipt(hash).then((result) => {
              if(maxPolling > 0) {
                if(result) {
                  if(result.status){
                    this.onCompleteClaim(result);
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    clearInterval(timer);
                  }else {
                    this.onFailClaim();
                    this.$store.commit("setShowLoading", { show: false, msg: "" });
                    clearInterval(timer);
                  }
                }
              } else {
                this.$store.commit("setShowLoading", { show: false, msg: "" });
                this.onFailClaim(); // alert message
                // this.$store.commit("setCommonAlertModalInfo", {
                //   msgHtml: this.$t("시간 내 처리되지 못했습니다. 다시 확인해주세요."),
                //   show: true,
                //   fontSize: "sm"
                // })
                clearInterval(timer);
              }
              maxPolling--;
            }), pollingInterval);
          })
          .catch((error) => {
            this.$log.error("sendTransaction error:", error);
            this.$store.commit("setShowLoading", { show: false, msg: "" });
            this.onFailClaim();
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    sendNext(data, functionType) {
      if (data.err) {
        this.closeOpenedModal();
        if (data.code === 6412) {
          alert(this.$t("stake.claim.failAmount")); //TODO 메시지 확인필요!
        } else {
          alert(this.$t("stake.failRequest") + "\n[" + data.code + "] " + data.err);
        }
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          if (this.isMobile){
            // 카카오톡 클립 > 정보제공 요청
            request(data.request_key, true);
            this.form = this.getInitPage();
            this.form.isKakaoKlipMobile = true;
          } else {
            // QR 코드 생성
            this.createQRCode("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
            if (this.klip.qrcode != null) {
              this.$refs["klipModal"].show();
            }
          }
          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setKlipTotalTimer();
          // 결과 조회
          this.setKlipResultTimer(data.request_key, functionType);
        } else {
          this.closeOpenedModal();
          if(this.isMobile){
            alert(this.$t("stake.failTryAgain"));
          } else {
            alert(this.$t("stake.failKakaoQR"));
          }
        }
      }
    },
    onClickPopupNoneSeenWeekBtn(idxArtpool) {
      this.$cookies.set("popupNotSeenForWeekArtPoolUserGuide" + idxArtpool, "done", "7d");
      if (idxArtpool === 1) {
        this.$refs["artPoolUserGuideModal"].hide();
      } else if (idxArtpool === 2) {
        this.$refs["artPoolUserGuideModal2"].hide();
      }
    },
    onClickTopRankNoneSeenWeekBtn(idxArtpool) {
      this.$cookies.set("popupNotSeenForWeekArtPoolResult" + idxArtpool , "done", "7d");
      if (idxArtpool === 1) {
        this.$refs["nftPoolTopRankImageModal"].hide();
      } else if (idxArtpool === 2) {
        this.$refs["nftPoolTopRankImageModal2"].hide();
      }
    },
    closeOpenedModal() {
      if(this.isOpenApprove){
        this.closeApprove();
      }

      if(this.isOpenStake) {
        this.closeNftStake();
      }

      if(this.isOpenUnstake) {
        this.closeNftUnstake();
      }
    },
    closeModal() {
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
    getCachedSession() {
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    claim(poolInfo) {
      this.checkKlaytnAddr()
        .then((hasKlatynAddr) => {
          if (hasKlatynAddr) {

            const walletProvider = this.getWalletProvider;

            const claimABI = {
              "inputs": [
                {
                  "internalType": "uint8",
                  "name": "round",
                  "type": "uint8"
                }
              ],
              "name": "claim",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            };


            const userReward = (Math.floor((poolInfo.userData.userReward * 100) / 10 ** 18) / 100)
              .toFixed(2)

            if(userReward < 0.01){
              this.$store.commit("setCommonAlertModalInfo", {
                msgHtml: this.$t("wallet.claim.noAmount"),
                show: true,
                fontSize: "sm"
              })
              return;
            }

            if (walletProvider === "Kaikas") {
              this.kaikasClaim(claimABI, poolInfo);
            } else if (walletProvider === "Klip") {
              this.klipClaim(claimABI, poolInfo);
            } else {
              alert(this.$t("wallet.validation.checkProvider"));
            }
          }
        })
    },
    getInitPage() {
      return {
        title: "CONNECT",//this.$t("wallet.modal.description"),
        isDefault: false,
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
        errorMessage: null,
        isLogin: false,
        isChange: false,
        isEthereum: false,
        isKlaytn: false,
      };
    },
    getInitKakaoKlip() {
      return {
        qrcode: null,
        requestKey: null
      };
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.klip.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.form.title = this.$t("wallet.modal.klipQRTitle");
          this.klip.qrcode = url;
        }
      });
    },
    setKlipTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);
            this.hide();
          }
        }, 1000);
      }
    },
    setKlipResultTimer(reqeustKey, functionType) {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(this.klip.requestKey)
            .then(data => {
              if (data.status === "completed") {
                if (data.result.status === "success") {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  // 열린 모달 닫기
                  this.$refs["klipModal"].hide();

                  if(functionType === "approvalForAll"){
                    if (this.isMobile) {
                      alert(this.$t("stake.staking.completeApprove"));
                    }
                    this.executeNftStake("KIP-17");
                  }else if(functionType === "stake") {
                    this.closeNftStake();

                    // 스테이킹 결과 저장
                    const param = {
                      idxArtpool: this.artpoolInfo.idx,
                      collections: this.prepareStakingCollections
                    };
                    this.completeStakeCollections(param)
                      .then(data => {
                        this.requestArtpool(param.idxArtpool);
                        this.$store.commit("setCommonAlertModalInfo", {
                          msgHtml: this.$t("stake.staking.success"),
                          show: true,
                          fontSize: "sm"
                        })
                      })
                      .catch(error => {
                        this.$log.error(error);
                      });
                  }else if(functionType === "unstake"){
                    this.closeNftUnstake();

                    // 언스테이킹 결과 저장
                    const param = {
                      idxArtpool: this.artpoolInfo.idx,
                      collections: this.unstakingCollections
                    };
                    this.completeUnstakeCollections(param)
                      .then(data => {
                        this.requestArtpool(param.idxArtpool);
                        this.$store.commit("setCommonAlertModalInfo", {
                          msgHtml: this.$t("stake.unstaking.success"),
                          show: true,
                          fontSize: "sm"
                        })
                      })
                      .catch(error => {
                        this.$log.error(error);
                      });
                  } else if(functionType === "claim"){
                    this.requestArtpool(this.artpoolInfo.idx);
                    this.$store.commit("setCommonAlertModalInfo", {
                      msgHtml: this.$t("stake.claim.success"),
                      show: true,
                      fontSize: "sm"
                    })
                  }
                  this.closeOpenedModal();

                } else if (data.result.status === "pending") {
                  // pending : success될때까지 실행
                  this.isTimeout = false;
                } else {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  this.$refs["klipModal"].hide();

                  // Fail
                  if(functionType === "approvalForAll"){
                    alert(this.$t("stake.failApprove"));
                  }else if(functionType === "stake") {
                    alert(this.$t("stake.staking.failRequest"));
                  }else if(functionType === "unstake"){
                    alert(this.$t("stake.unstaking.failRequest"));
                  } else if(functionType === "claim"){
                    alert(this.$t("stake.claim.fail"));
                  }
                  this.closeOpenedModal();
                }
              } else if (data.status === "canceled") {
                this.closeModal();
                this.closeOpenedModal();
              }
            })
            .catch(error => {
              this.$log.error(error);
              clearInterval(this.timer);
            });
        }, 3000);
      }
    },
    // SmartContract ABI
    getABIApprovalForAll() {
      return {
        "inputs": [
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      };
    },
    getABINftBatchStake() {
      return {
        "inputs": [
          {
            "internalType": "uint8",
            "name": "round",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "nftAddress",
            "type": "address"
          },
          {
            "internalType": "uint256[]",
            "name": "tokenIds",
            "type": "uint256[]"
          }
        ],
        "name": "batchStake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      };
    },
    getABINftBatchUnstake() {
      return {
        "inputs": [
          {
            "internalType": "uint8",
            "name": "round",
            "type": "uint8"
          },
          {
            "internalType": "address",
            "name": "nftAddress",
            "type": "address"
          },
          {
            "internalType": "uint256[]",
            "name": "tokenIds",
            "type": "uint256[]"
          }
        ],
        "name": "batchUnstake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      };
    },
    getABIClaim() {
      return {
        "inputs": [
          {
            "internalType": "uint8",
            "name": "round",
            "type": "uint8"
          }
        ],
        "name": "claim",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      };
    },
    onClickArtPoolUserGuide() {
      if (this.currentArtPool === 1) {
        this.$refs["artPoolUserGuideModal"].show();
      } else if (this.currentArtPool === 2) {
        this.$refs["artPoolUserGuideModal2"].show();
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    closeClaimModal() {
      this.$refs.claimModal.hide();
    },
    claimRequest() {
      if(!Web3.utils.isAddress(this.claimEthereumAddress)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: "올바른 이더리움 주소를 입력해주세요",
          show: true,
          fontSize: "sm"
        })
        return false;
      }
      const payload = {
        idxArtpool: this.artpoolSimpleInfo.roundNo,
        network:"ETHEREUM",
        amount: this.nftStakingInfo.kipUserData.userReward,
        currency:"PACE",
        memo: this.claimEthereumAddress,
        transactionType: `ClaimArtpool${this.artpoolSimpleInfo.roundNo}`
      }
      this.requestClaimPace = true;
      this.claimRewardByArtpool(payload).then((result) => {
        this.requestClaimPace = false;
        if(result > 0) {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: "클레임 요청이 완료 되었습니다. 수분 내에 지급 될 예정입니다.",
            show: true,
            fontSize: "sm"
          })
          this.nftStakingInfo.kipUserData.userReward = "0";
        } else {
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: "클레임 요청에 실패하였습니다.",
            show: true,
            fontSize: "sm"
          })
        }
        this.closeClaimModal();
      })
    }
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.totalTimer);
  }
}
</script>
